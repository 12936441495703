import React from 'react'
import Layout from 'components/layout'
import ContactForm from 'components/ContactForm/'
import { enumTheme } from 'config'
import { getCurrentLang } from 'utils'
import { getPageTitle } from '../slugs'

export default props => {
  const {
    data: {
      illustrationContact,
      informationComplementaire,
      contactNomInputs,
      reponseContactOk,
      reponseContactKo
    },
    slugs,
    lang
  } = props

  return (
    <Layout
      slugs={slugs}
      lang={lang}
      pageTitle={getPageTitle('contact', lang)}
      headerScroll={0}
      footerTheme={enumTheme.WHITE}
      socialMediaIsDark={false}
      socialMediaScroll={0}
      socialMediaOffset={0}
      baseUrl={getCurrentLang(props.location.pathname)}
      currentPage={ props.currentPage }
    >
      <ContactForm
        background={illustrationContact}
        description={informationComplementaire}
        inputsName={JSON.parse(contactNomInputs)}
        reponseContactOk={reponseContactOk}
        reponseContactKo={reponseContactKo}
      />
    </Layout>
  )
}
