import React from 'react'
import ReactMarkdown from 'react-markdown'
import styles from './contactForm.module.css'
import calendar from './assets/calendar.png'
import ReCAPTCHA from 'react-google-recaptcha'

export default props => (
  <div
    className={styles.contactForm}
    style={{
      backgroundImage: `url(${ props.background && props.background.fluid.src })`
    }}>
    <div className={styles.formContain}>
      <form
        className={styles.form}
        method="post"
        action="https://lizardsforms.comdeslezards.com/send/cala_rossa">
        <input value={props.reponseContactOk} name="successMessage" type="hidden"/>
        <input value={props.reponseContactKo} name="failMessage" type="hidden"/>
        <div className={styles.formTop}>
          <div className={styles.formTopTitles}>
            <h3>Contact</h3>
            <h4>Villa Rossa Cala Rossa</h4>
          </div>

          <div className={styles.formTopInputs}>
            <div className={styles.left}>
              <div className={styles.leftTop}>
                <label htmlFor="depart">
                  {props.inputsName.depart}
                  <input id="depart" name="from" type="date"/>
                  <img src={calendar} alt="depart"/>
                </label>
                <label htmlFor="arrivee">
                  {props.inputsName.arrive}
                  <input id="arrivee" name="to" type="date"/>
                  <img src={calendar} alt="arrivee"/>
                </label>
              </div>
              <div className={styles.leftBottom}>
                <label htmlFor="adultes">
                  {props.inputsName.adult}
                  <select id="adultes" name="adults">
                    <option value=''></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </label>
                <label htmlFor="enfants">
                  {props.inputsName.child}
                  <select id="enfants" name="childs">
                    <option value=''></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </label>
              </div>
            </div>
            <div className={styles.separator}></div>
            <div className={styles.right}>
              <label htmlFor="nom">
                {props.inputsName.name}*
                <input id="nom" name="name" type="text" required/>
              </label>
              <label htmlFor="adresse">
                {props.inputsName.address}
                <input id="adresse" name="address" type="text"/>
              </label>
              <label htmlFor="telephone">
                {props.inputsName.tel}
                <input id="telephone" name="phone" type="tel"/>
              </label>
              <label htmlFor="email">
                {props.inputsName.mail}*
                <input id="email" name="email" type="email" required/>
              </label>
            </div>
          </div>

        </div>
        <div className={styles.formBottom}>
          <textarea name="message" placeholder={props.inputsName.yourmessage} required></textarea>
          {/* <label className={styles.checkbox_container}>
            <input type="checkbox" required />
            <span class={styles.checkmark}></span>
            En soumettant ce formulaire, j'accepte que les informations saisies dans ce formulaire soient utilisées dans le cadre de la demande d'informations et de la relation commerciale qui peut en découler.
          </label> */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '1rem'
            }}><ReCAPTCHA sitekey="6Le9wZMUAAAAAE8s2c3mN2xmmWkWigVCfES0kFr0"/></div>
          <button className="pinkButton" type="submit">{props.inputsName.send}</button>
          <ReactMarkdown source={props.description}/>
        </div>
      </form>
    </div>

  </div>
)
